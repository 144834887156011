header {
  background-color: transparent;
  width: 100%;
  min-height: 150px;
  z-index: 10;
}
header .wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  min-height: 125px;
  text-align: center;
}
@media all and (max-width: 1300px) {
  header .wrapper {
    justify-content: center;
  }
}
header .wrapper .logo {
  height: 125px;
  margin-left: 2%;
  margin-top: 15px;
}
@media all and (max-width: 850px) {
  header .wrapper .logo {
    margin: 15px auto;
  }
}
header .wrapper .logo img {
  width: 411.2px;
}
@media all and (max-width: 420px) {
  header .wrapper .logo img {
    width: 250px;
  }
}
header .wrapper .navContainer {
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  height: 100%;
  display: flex;
  align-items: center;
  width: 1000px;
  justify-content: right;
  margin-right: 2%;
}
@media all and (max-width: 850px) {
  header .wrapper .navContainer {
    margin-right: initial;
  }
}
header .wrapper .navContainer nav {
  display: flex;
  font-size: 24px;
  white-space: nowrap;
  width: 100%;
}
header .wrapper .navContainer nav ul {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media all and (max-width: 850px) {
  header .wrapper .navContainer nav ul {
    flex-direction: column;
  }
  header .wrapper .navContainer nav ul li {
    text-align: center;
    background-color: rgba(0,0,0,0.271);
    border-bottom: 1px solid #3b4857;
    padding: 5px 0;
  }
}
header .wrapper .navContainer nav ul li {
  list-style: none;
}
header .wrapper .navContainer nav ul li a {
  margin: 0 10px;
  color: #dcdcdc;
  text-decoration: none;
  outline: none;
}
header .wrapper .navContainer nav ul li a:hover,
header .wrapper .navContainer nav ul li a.active {
  color: #357fd7;
}
footer {
  width: 100%;
  background-color: #1e1e1e;
  color: #fff;
  overflow: hidden;
  height: 100px;
  display: flex;
}
footer .footerImage {
  height: 100%;
  display: inline-flex;
  align-items: center;
}
footer .footerImage img {
  height: 90%;
}
footer .footerContact {
  align-self: center;
  position: absolute;
  right: 50px;
  color: #f5f5f5;
}
footer .footerContact .address,
footer .footerContact .phone {
  margin: 15px 0;
  text-align: right;
}
footer .footerContact a {
  color: inherit;
  text-decoration: none;
}
footer .socialButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 120px;
  margin: 10px auto 0;
}
footer .socialButtons h6 {
  margin: 0 0 2px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 300;
  color: #000;
  order: 0;
}
footer .socialButtons img {
  height: 32px;
  width: 32px;
  margin: 0 2px;
  filter: grayscale(1);
  transition: filter 0.2s;
  order: 1;
}
footer .socialButtons img:hover {
  filter: grayscale(0);
}
footer .socialButtons .copyright {
  width: 100%;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  color: #000;
  margin-top: 2px;
  order: 2;
}
@media all and (max-width: 700px) {
  footer .footerContact {
    display: none;
  }
}
.button {
  padding: 10px 40px;
  text-decoration: none;
  font-size: 24px;
  border: 1px solid #357fd7;
  background-color: #2a78d5;
  color: #ebebeb;
  border-radius: 3px;
}
.button.clear {
  padding: 10px;
  border: 1px solid #a4a4a4;
  background-color: rgba(0,0,0,0.349);
  text-align: center;
}
.marginCenter {
  margin: 0 auto;
}
.center {
  text-align: center;
}
.banner {
  width: 100%;
  padding-bottom: 50px;
}
.banner p {
  text-align: center;
  font-size: 42px;
  padding: 25px 0;
  width: 45%;
  margin: 0 auto;
}
.banner .buttonWrapper {
  text-align: center;
  margin: 10px 0;
}
.banner .buttonWrapper .button {
  display: inline;
}
.homePage .headerExtension {
  min-height: 1070px;
  background: url("0101ed3729ef0ab6c3823298372e22da.jpg");
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.homePage .headerExtension .text {
  padding: 15px 30px;
  margin-top: 50px;
  color: #fff;
  max-width: 900px;
  transition: 0.2s top;
}
.homePage .headerExtension .text .topText,
.homePage .headerExtension .text .bottomText,
.homePage .headerExtension .text .callUsNow {
  font-size: calc(12px + 3vmin);
}
.homePage .headerExtension .text .topText span,
.homePage .headerExtension .text .bottomText span,
.homePage .headerExtension .text .callUsNow span {
  color: #357fd7;
}
.homePage .headerExtension .text .bottomText {
  padding-top: 5%;
}
.homePage .headerExtension .text .bottomText a {
  color: #357fd7;
  background-color: rgba(0,0,0,0.25);
  padding: 5px;
  border-radius: 1px;
}
.homePage .headerExtension .text .middleText {
  font-size: calc(12px + 7vmin);
  color: #357fd7;
}
.homePage .why {
  margin: 0 auto;
  padding-top: 20px;
  background-color: #ebebeb;
}
.homePage .why .topTitle {
  border-top: 2px solid #ececec;
  font-size: 42px;
  text-align: center;
}
.homePage .why .topText {
  width: 70%;
  padding: 15px 0;
  margin: 0 auto;
  font-size: 24px;
  text-align: justify;
}
.homePage .why h2 {
  margin: 0;
  padding: 20px 100px;
  font-size: 28px;
  font-weight: 500;
}
.homePage .why .contactWrapper {
  text-align: center;
  padding: 30px 0;
}
.homePage .services {
  background-color: #d9d9d9;
}
.homePage .services .blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homePage .services .blocks .block {
  flex: 1;
  min-height: 350px;
  min-width: 510px;
  position: relative;
  overflow: hidden;
  text-align: justify;
  display: block;
  text-decoration: none;
}
.homePage .services .blocks .block:hover .backgroundImage {
  filter: brightness(0.6) blur(0);
}
@media all and (max-width: 700px) {
  .homePage .services .blocks .block {
    flex: initial;
    min-width: initial;
  }
}
.homePage .services .blocks .block .backgroundImage {
  filter: brightness(0.4) blur(2px);
  transition: 0.2s filter;
  padding: 5px;
  margin: -5px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
}
.homePage .services .blocks .block .backgroundImage.pointofsale {
  background-image: url("41d708fb0b25f0bfbeccacfd5f6e0af1.jpg");
  background-size: cover;
  background-position-y: -300px;
}
.homePage .services .blocks .block .backgroundImage.electrical {
  background-image: url("4b1ca33a87da4322d381f34ac82d6077.jpg");
}
.homePage .services .blocks .block .backgroundImage.techsupport {
  background-image: url("a5389165dc2a554a6d4d5c5ee54c6436.jpg");
}
.homePage .services .blocks .block .content {
  position: relative;
  color: #fff;
  height: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}
.homePage .services .blocks .block .content .title {
  text-align: center;
  font-size: 36px;
}
.homePage .services .blocks .block .content p {
  text-align: justify;
  width: 80%;
  font-size: 22px;
  margin: 0 auto;
  min-height: 170px;
}
.homePage .services .blocks .block .content .learnMore {
  margin: 15px auto;
  width: 77%;
}
.pointofsalePage .headerExtension {
  height: 950px;
  background-color: #123259;
  position: relative;
  overflow: hidden;
  background-image: url("6819acfeb60423cc31d10144651715ce.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-position-x 0.2s;
  background-position-x: 50%;
}
.pointofsalePage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.pointofsalePage .headerExtension .text {
  position: relative;
  top: 450px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
@media all and (max-width: 850px) {
  .pointofsalePage .headerExtension .text {
    top: 120px;
  }
}
.pointofsalePage .headerExtension .text .topText {
  font-size: 38px;
}
.pointofsalePage .headerExtension .text .topText span {
  color: #357fd7;
}
.pointofsalePage .headerExtension .text p {
  font-size: 22px;
}
.pointofsalePage .info {
  padding: 50px 0;
  background-color: #d9d9d9;
}
.pointofsalePage .info .text {
  width: 75%;
  margin: 0 auto;
}
.pointofsalePage .info .text .topTitle {
  text-align: center;
  font-size: 28px;
  color: #357fd7;
}
.pointofsalePage .info .text p {
  text-align: center;
  font-size: 24px;
  width: 70%;
  margin: 15px auto;
}
.pointofsalePage .info .blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 75px;
}
.pointofsalePage .info .blocks .block {
  width: 500px;
  height: 350px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  text-decoration: none;
}
.pointofsalePage .info .blocks .block .backgroundImage {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  padding: 5px;
  margin: -5px;
  filter: brightness(0.4) blur(2px);
  transition: 0.2s filter;
}
.pointofsalePage .info .blocks .block .backgroundImage.partners {
  background-image: url("538b783da033d079306667fd46f504be.jpg");
}
.pointofsalePage .info .blocks .block .backgroundImage.merchant {
  background-image: url("41d708fb0b25f0bfbeccacfd5f6e0af1.jpg");
}
.pointofsalePage .info .blocks .block .backgroundImage.hardware {
  background-image: url("86f018ed36f79fdd2aa9c52515a0e349.jpg");
  filter: brightness(1);
}
.pointofsalePage .info .blocks .block .title {
  position: relative;
  color: #fff;
  font-size: 36px;
  text-align: center;
  padding: 0;
  top: 15px;
}
.pointofsalePage .info .blocks .block .subtitle {
  position: relative;
  color: #fff;
  font-size: 20px;
  text-align: center;
  padding: 0;
  top: 20px;
}
.pointofsalePage .info .blocks .block p {
  position: relative;
  color: #fff;
  font-size: 24px;
  text-align: center;
  padding: 45px;
  height: 100px;
}
.pointofsalePage .info .blocks .block .notice {
  color: #fff;
  position: relative;
  text-align: center;
  font-size: 14px;
}
.pointofsalePage .info .blocks .block .button {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.hardwarePage .headerExtension {
  min-height: 450px;
  background-color: #123259;
  position: relative;
  overflow: hidden;
  background-image: url("86f018ed36f79fdd2aa9c52515a0e349.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s background-position-y;
  background-position-y: calc(-320px);
}
@media all and (max-width: 1400px) {
  .hardwarePage .headerExtension {
    background-position-y: initial;
  }
}
.hardwarePage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.hardwarePage .headerExtension .text {
  padding: 25px 0 50px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
.hardwarePage .headerExtension .text .topText {
  font-size: 38px;
}
.hardwarePage .headerExtension .text .topText span {
  color: #357fd7;
}
.hardwarePage .headerExtension .text p {
  font-size: 22px;
}
.hardwarePage .hereAre {
  font-size: 36px;
  text-align: center;
}
.hardwarePage .retail {
  width: 100%;
  min-height: 500px;
  background-image: url("33aaf4bd8c1e52a7665888a6a664bc63.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s background-position-y;
  background-position-y: -150px;
}
@media all and (max-width: 1400px) {
  .hardwarePage .retail {
    background-position-y: initial;
  }
}
@media all and (max-width: 550px) {
  .hardwarePage .retail {
    background-position-x: -450px;
  }
}
.hardwarePage .retail .title {
  font-size: 32px;
  text-align: center;
}
.hardwarePage .retail .content {
  color: #fff;
  padding: 25px 0 50px;
}
.hardwarePage .retail .content .suitable {
  margin-top: 20px;
}
.hardwarePage .retail .content .suitable .for {
  text-align: center;
  font-size: 28px;
}
.hardwarePage .retail .content .suitable .options {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 10px auto 50px;
  flex-wrap: wrap;
}
.hardwarePage .retail .content .suitable .options .item {
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 150px;
  margin-top: 10px;
}
.hardwarePage .retail .content .suitable .options .item .image {
  display: flex;
  background-color: rgba(6,6,6,0.651);
  height: 100px;
  border-radius: 20px;
  padding: 5px;
  width: 100px;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.hardwarePage .retail .content .suitable .options .item .image img {
  opacity: 0.7;
  padding-top: 10px;
  height: 70%;
  width: 70%;
}
.hardwarePage .retail .content .suitable .options .item span {
  padding-top: 5px;
  font-size: 18px;
}
.hardwarePage .retail .content .text {
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.hardwarePage .retail .content .text .startingAt {
  font-size: 32px;
  text-align: center;
}
.hardwarePage .retail .content .text p {
  font-size: 24px;
  text-align: center;
}
.hardwarePage .restaurant {
  width: 100%;
  min-height: 400px;
  background-image: url("6274cda2024103ced564f8f33c5287e7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s background-position-x;
  background-position-x: calc(100%);
}
.hardwarePage .restaurant .title {
  font-size: 32px;
  text-align: center;
}
.hardwarePage .restaurant .content {
  color: #fff;
  padding: 25px 0 50px;
}
.hardwarePage .restaurant .content .packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.hardwarePage .restaurant .content .packages .package {
  width: 500px;
  padding: 15px 15px 45px;
  margin: 20px 0;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
}
.hardwarePage .restaurant .content .packages .package .name {
  font-size: 32px;
}
.hardwarePage .restaurant .content .packages .package .startingAt {
  font-size: 24px;
}
.hardwarePage .restaurant .content .packages .package .includes {
  font-size: 18px;
  margin-top: 5px;
}
.hardwarePage .restaurant .content .packages .package p {
  font-size: 22px;
  position: relative;
  top: 25px;
}
.merchantsolutionsPage .headerExtension {
  height: 450px;
  background-color: #123259;
  position: relative;
  overflow: hidden;
  background-image: url("6819acfeb60423cc31d10144651715ce.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-position-x 0.2s;
  background-position-x: 50%;
}
.merchantsolutionsPage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.merchantsolutionsPage .headerExtension .text {
  position: relative;
  top: 50px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
@media all and (max-width: 850px) {
  .merchantsolutionsPage .headerExtension .text {
    top: 120px;
  }
}
.merchantsolutionsPage .headerExtension .text .topText {
  font-size: 38px;
}
.merchantsolutionsPage .headerExtension .text .topText span {
  color: #357fd7;
}
.merchantsolutionsPage .headerExtension .text p {
  font-size: 22px;
}
.merchantsolutionsPage .info {
  padding: 50px 0;
  background-color: #d9d9d9;
}
.merchantsolutionsPage .info .text {
  margin: 0 auto;
}
.merchantsolutionsPage .info .text .topTitle {
  text-align: center;
  font-size: 28px;
  color: #357fd7;
}
.merchantsolutionsPage .info .text .lists {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  align-content: space-betweenq;
}
.merchantsolutionsPage .info .text .lists ul {
  flex: 1;
  min-width: 500px;
  list-style-type: disc;
  font-size: 24px;
}
.merchantsolutionsPage .info .text .lists ul.longList li {
  padding: 5px 0;
}
.merchantsolutionsPage .info .text .lists ul li {
  width: 50%;
  margin: 0 auto;
}
.merchantsolutionsPage .info .text .lists ul li span {
  font-weight: bold;
}
.electricalPage .headerExtension {
  min-height: 450px;
  background-color: #123259;
  position: relative;
  background-image: url("bd16d941944207217632895febcf3f96.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.electricalPage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.electricalPage .headerExtension .text {
  padding: 25px 0 50px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
.electricalPage .headerExtension .text .topText {
  font-size: 38px;
}
.electricalPage .headerExtension .text .topText span {
  color: #357fd7;
}
.electricalPage .headerExtension .text p {
  font-size: 22px;
}
.electricalPage .info {
  background-color: #d9d9d9;
}
.electricalPage .info .text {
  width: 75%;
  margin: 0 auto;
}
.electricalPage .info .text .topTitle {
  text-align: center;
  font-size: 28px;
  color: #357fd7;
}
.electricalPage .info .text p {
  text-align: center;
  font-size: 24px;
  width: 70%;
  margin: 15px auto;
}
.electricalPage .info .blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 2px solid #d3d3d3;
}
.electricalPage .info .blocks .block {
  background-color: #cecece;
  padding: 50px 100px;
  color: #000;
}
.electricalPage .info .blocks .block:nth-child(odd) {
  background-color: #d9d9d9;
}
.electricalPage .info .blocks .block .title {
  color: #357fd7;
  font-size: 44px;
  padding-bottom: 15px;
}
.electricalPage .info .blocks .block .image {
  width: 100px;
  margin: 0 auto;
}
.electricalPage .info .blocks .block span {
  font-size: 24px;
  color: #357fd7;
}
.electricalPage .info .blocks .block p {
  font-size: 22px;
}
.electricalPage .info .blocks .block .button {
  position: relative;
  top: 30px;
  width: 80%;
  margin: 0 auto;
}
.electricalPage .info .blocks .block:hover .backgroundImage {
  filter: brightness(0.3) blur(0);
}
.techsupportPage .headerExtension {
  min-height: 450px;
  background-color: #123259;
  position: relative;
  background-image: url("7e29999bfa7cda2cdebe4e80d7d4e571.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.techsupportPage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.techsupportPage .headerExtension .text {
  padding: 25px 0 50px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
.techsupportPage .headerExtension .text .topText {
  font-size: 38px;
}
.techsupportPage .headerExtension .text .topText span {
  color: #357fd7;
}
.techsupportPage .headerExtension .text p {
  font-size: 26px;
}
.techsupportPage .headerExtension .text .bottomText {
  font-size: 22px;
}
.techsupportPage .blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px 0;
  border-bottom: 2px solid #d3d3d3;
}
.techsupportPage .blocks .block {
  width: 600px;
}
.techsupportPage .blocks .block .remote {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
}
.techsupportPage .blocks .block .remote .title {
  font-size: 42px;
  color: #357fd7;
  text-align: center;
}
.techsupportPage .blocks .block .remote p {
  font-size: 22px;
  text-align: justify;
  padding: 10px 0;
}
.techsupportPage .blocks .block .remote .image {
  width: 150px;
  justify-self: center;
  align-self: center;
}
.techsupportPage .blocks .block .remote .image img {
  width: 100%;
}
.aboutPage .headerExtension {
  height: 450px;
  background-color: #123259;
  position: relative;
  overflow: hidden;
}
.aboutPage .headerExtension .backgroundImage {
  background-image: url("https://images.unsplash.com/photo-1544197150-b99a580bb7a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s background-position-x;
  background-position-x: calc(100%);
  filter: brightness(0.4);
}
.aboutPage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.aboutPage .headerExtension .text {
  position: absolute;
  top: 175px;
  text-align: center;
  padding: 0 30px;
  color: #fff;
  width: 100%;
  transition: 0.2s top;
}
.aboutPage .headerExtension .text .topText {
  font-size: 38px;
}
.aboutPage .headerExtension .text .topText span {
  color: #357fd7;
}
.aboutPage .headerExtension .text p {
  font-size: 26px;
}
.aboutPage .headerExtension .text .bottomText {
  font-size: 22px;
}
.aboutPage .team {
  padding: 10px 0;
  background-color: #c3c3c3;
}
.aboutPage .team .title {
  font-size: 42px;
  color: #357fd7;
  text-align: center;
}
.aboutPage .team .subtitle {
  text-align: center;
  font-size: 24px;
}
.aboutPage .team .pictures {
  display: flex;
  justify-content: space-around;
  padding: 50px;
}
.aboutPage .team .pictures .picture {
  width: 220px;
  height: 220px;
  border-radius: 50px;
  overflow: hidden;
}
.aboutPage .team .pictures .picture img {
  width: 100%;
}
.aboutPage .team .pictures .name {
  font-size: 24px;
  text-align: center;
}
.aboutPage .team .bottomText .title {
  text-align: center;
  color: #357fd7;
  font-size: 32px;
  margin-bottom: 20px;
}
.aboutPage .team .bottomText .getInTouch {
  width: 250px;
  text-align: center;
  display: block;
  margin: 0 auto;
}
.contactPage .headerExtension {
  min-height: 900px;
  background-color: #d9d9d9;
  position: relative;
  overflow: hidden;
  background-image: url("9db209b54de1d4181f7588d2f687be63.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s background-position-y;
  background-position-y: calc(-320px);
}
@media all and (max-width: 1400px) {
  .contactPage .headerExtension {
    background-position-y: initial;
  }
}
.contactPage .headerExtension .blur {
  width: 120%;
  height: 50px;
  position: absolute;
  bottom: -25px;
  filter: blur(8px);
  z-index: 208;
  background-color: #d9d9d9;
  margin: 0 -5%;
}
.contactPage .headerExtension .text {
  padding: 25px 0 50px;
  text-align: center;
  color: #fff;
  width: 90%;
  margin: 0 auto;
}
.contactPage .headerExtension .text .topText {
  font-size: 38px;
}
.contactPage .headerExtension .text .topText span {
  color: #357fd7;
}
.contactPage .headerExtension .text p {
  font-size: 22px;
}
.contactPage .headerExtension .text .callUs {
  font-size: 34px;
  margin-top: 15px;
}
.contactPage .headerExtension .text .phone {
  font-size: 32px;
}
.contactPage .headerExtension .text .phone a {
  color: inherit;
  text-decoration: none;
}
.contactPage form {
  width: 80%;
  margin: 0 auto;
}
.contactPage form .inputWrapper {
  display: flex;
  flex-wrap: wrap;
}
.contactPage form .inputWrapper input {
  flex: 1;
  padding: 10px;
  margin: 0 15px;
  background-color: #f5f5f5;
  border: 1px solid #cfcfcf;
  color: #000;
}
.contactPage form .textArea {
  margin-top: 10px;
  width: 100%;
}
.contactPage form .textArea textarea {
  height: 200px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  resize: none;
  background-color: #f5f5f5;
  border: 1px solid #cfcfcf;
  color: #000;
}
.contactPage form .submitWrapper {
  display: flex;
  justify-content: right;
}
.contactPage form .submitWrapper button {
  margin-top: 10px;
  cursor: pointer;
}
.contactPage form .submitWrapper button:hover {
  background-color: #266cc0;
}
.bestseller .headerExtension {
  min-height: 1500px;
  background: linear-gradient(#000, #2c55b1, #e2e2e2);
}
.bestseller .headerExtension .rhombus {
  margin-top: 90px;
  margin-left: 48%;
  max-width: 900px;
  color: #f5f5f5;
  font-size: 27px;
}
@media all and (max-width: 1900px) {
  .bestseller .headerExtension .rhombus {
    margin-right: auto;
    margin-left: auto;
  }
}
@media all and (max-width: 615px) {
  .bestseller .headerExtension .rhombus {
    font-size: 20px;
  }
}
.bestseller .headerExtension .rhombus h2 {
  text-align: center;
  padding-bottom: 40px;
}
.bestseller .headerExtension .rhombus p {
  width: 100%;
  text-align: center;
}
.bestseller .headerExtension .rhombusimage {
  position: absolute;
  width: 1000px;
  top: 200px;
  padding-top: 40px;
  animation: zoom-in-zoom-out 2s ease;
}
@media all and (max-width: 1900px) {
  .bestseller .headerExtension .rhombusimage {
    max-width: 550px;
    top: 600px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
@media all and (max-width: 851px) {
  .bestseller .headerExtension .rhombusimage {
    margin-top: 270px;
    width: 280px;
  }
}
.bestseller .headerExtension .rhombusimage h2 {
  text-align: center;
  padding-bottom: 20px;
  font-size: 90px;
}
.bestseller .headerExtension .feedbacks {
  color: #fff;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin-left: 48%;
  margin-top: 200px;
  padding-bottom: 100px;
}
@media all and (max-width: 1900px) {
  .bestseller .headerExtension .feedbacks {
    margin: auto;
    margin-top: 700px;
    width: 70%;
  }
}
@media all and (max-width: 615px) {
  .bestseller .headerExtension .feedbacks {
    width: 100%;
  }
}
@media all and (max-width: 615px) {
  .bestseller .headerExtension .feedbacks {
    margin-top: 110%;
  }
}
.bestseller .headerExtension .feedbacks h2 {
  text-align: center;
  padding: 90px 0;
}
@media all and (max-width: 615px) {
  .bestseller .headerExtension .feedbacks h2 {
    font-size: 30px;
  }
}
.bestseller .headerExtension .feedbacks .text {
  padding: 50px;
  font-size: 25px;
  text-align: center;
}
@media all and (max-width: 615px) {
  .bestseller .headerExtension .feedbacks .text {
    font-size: 18px;
  }
}
.opeingmark {
  width: 30px;
  height: 30px;
}
.closingmark {
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
  margin-left: auto;
}
.shadow {
  box-shadow: 0px 5px 30px #f5f5f5;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.shadow:hover {
  transform: scale(1.01); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
@-moz-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.8, 1.8);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.8, 1.8);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-o-keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.8, 1.8);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.8, 1.8);
  }
  50% {
    transform: scale(1.03, 1.03);
  }
  100% {
    transform: scale(1, 1);
  }
}
.datamanagement .headerExtension {
  min-height: 1800px;
  background: linear-gradient(#000, #ff8c00, #e2e2e2);
}
.datamanagement .headerExtension .about {
  color: #fff;
  font-size: 23px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  padding-top: 150px;
  text-align: center;
}
@media all and (max-width: 697px) {
  .datamanagement .headerExtension .about {
    font-size: 20px;
  }
}
.datamanagement .headerExtension .about h1 {
  padding-bottom: 40px;
}
@media all and (max-width: 540px) {
  .datamanagement .headerExtension .about h1 {
    font-size: 30px;
  }
}
.datamanagement .headerExtension .test1 {
  display: flex;
}
.datamanagement .headerExtension .test1 img {
  position: absolute;
  width: 800px;
  top: 600px;
  right: 50px;
  animation: zoom-in-zoom-out 2s ease;
}
@media all and (max-width: 1730px) {
  .datamanagement .headerExtension .test1 img {
    width: 600px;
    margin: auto;
    left: 0;
    right: 0;
  }
}
@media all and (max-width: 851px) {
  .datamanagement .headerExtension .test1 img {
    margin-top: 300px;
  }
}
@media all and (max-width: 697px) {
  .datamanagement .headerExtension .test1 img {
    width: 100vw;
    margin-top: 200px;
  }
}
@media all and (max-width: 540px) {
  .datamanagement .headerExtension .test1 img {
    margin-top: 350px;
  }
}
@media all and (max-width: 420px) {
  .datamanagement .headerExtension .test1 img {
    margin-top: 470px;
  }
}
@media all and (max-width: 283px) {
  .datamanagement .headerExtension .test1 img {
    margin-top: 600px;
  }
}
.datamanagement .headerExtension .test1 p {
  font-size: 28px;
  color: #fff;
  width: 1000px;
  margin-top: 230px;
  padding-left: 100px;
  text-align: center;
}
@media all and (max-width: 1730px) {
  .datamanagement .headerExtension .test1 p {
    width: 800px;
    margin: auto;
    margin-top: 420px;
    padding: 0;
  }
}
@media all and (max-width: 697px) {
  .datamanagement .headerExtension .test1 p {
    width: 600px;
  }
}
@media all and (max-width: 540px) {
  .datamanagement .headerExtension .test1 p {
    font-size: 20px;
  }
}
.datamanagement .headerExtension .test2 {
  display: flex;
}
.datamanagement .headerExtension .test2 img {
  position: absolute;
  width: 700px;
  top: 1050px;
  right: 50px;
  animation: zoom-in-zoom-out 2s ease;
}
@media all and (max-width: 1730px) {
  .datamanagement .headerExtension .test2 img {
    width: 550px;
    margin: auto;
    margin-top: 40px;
    left: 0;
    right: 0;
  }
}
@media all and (max-width: 851px) {
  .datamanagement .headerExtension .test2 img {
    margin-top: 300px;
  }
}
@media all and (max-width: 697px) {
  .datamanagement .headerExtension .test2 img {
    margin-top: 450px;
    width: 100vw;
  }
}
@media all and (max-width: 540px) {
  .datamanagement .headerExtension .test2 img {
    margin-top: 290px;
  }
}
@media all and (max-width: 538px) {
  .datamanagement .headerExtension .test2 img {
    margin-top: 450px;
  }
}
@media all and (max-width: 283px) {
  .datamanagement .headerExtension .test2 img {
    margin-top: 660px;
  }
}
.datamanagement .headerExtension .test2 p {
  font-size: 28px;
  color: #fff;
  text-align: center;
  max-width: 1000px;
  margin-top: 350px;
  padding-left: 100px;
}
@media all and (max-width: 1730px) {
  .datamanagement .headerExtension .test2 p {
    width: 800px;
    margin: auto;
    margin-top: 300px;
    padding: 0;
  }
}
@media all and (max-width: 851px) {
  .datamanagement .headerExtension .test2 p {
    margin-top: 260px;
  }
}
@media all and (max-width: 697px) {
  .datamanagement .headerExtension .test2 p {
    width: 600px;
  }
}
@media all and (max-width: 540px) {
  .datamanagement .headerExtension .test2 p {
    font-size: 20px;
  }
}
.datamanagement .headerExtension .contact {
  text-align: center;
  margin-top: 300px;
  font-size: 40px;
  font-weight: bold;
}
@media all and (max-width: 1730px) {
  .datamanagement .headerExtension .contact {
    padding-bottom: 100px;
  }
}
@media all and (max-width: 400px) {
  .datamanagement .headerExtension .contact {
    font-size: 30px;
  }
}
.datamanagement .headerExtension .contact a {
  text-decoration: none;
  color: #000;
}
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  outline: none;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button::-moz-focus-inner {
  border: 0;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Proxima Nova Alt";
  height: 100%;
  background-color: #d9d9d9;
}
html {
  position: relative;
}
html #app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
